/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react'
import styled from 'styled-components'

import { tokens } from '@JOTAJornalismo/jota-design-system'

const { colors, fonts } = tokens

interface PassInterface {
    passwordRules: {
        hasUpper: boolean | null
        hasLower: boolean | null
        hasNumber: boolean | null
        has8Chars: boolean | null
        hasPasswordRewrite: boolean | null
    }
    setPasswordRules: any
}

const MinusIcon = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M18 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z'
            fill='current'
        />
    </svg>
)

const CheckIcon = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M9.00014 16.1698L5.53014 12.6998C5.14014 12.3098 4.51014 12.3098 4.12014 12.6998C3.73014 13.0898 3.73014 13.7198 4.12014 14.1098L8.30014 18.2898C8.69014 18.6798 9.32014 18.6798 9.71014 18.2898L20.2901 7.70982C20.6801 7.31982 20.6801 6.68982 20.2901 6.29982C19.9001 5.90982 19.2701 5.90982 18.8801 6.29982L9.00014 16.1698Z'
            fill='current'
        />
    </svg>
)

/**
 * Password Requirements
 * @param {PassInterface} passwordRules``
 * @param {any} setPasswordRules
 * @return {ReactElement}
 */
const PasswordRequirements: FC<PassInterface> = ({
    passwordRules
}): ReactElement => (
    <div className='reset-password-requirements-container'>
        <div className='reset-password-line-one'>
            <CheckItem
                status={passwordRules.has8Chars}
                className={passwordRules.has8Chars ? 'checked' : 'unchecked'}
            >
                {passwordRules.has8Chars ? <CheckIcon /> : <MinusIcon />}
                <span>Pelo menos 8 caracteres</span>
            </CheckItem>
            <CheckItem
                status={passwordRules.hasLower}
                className={passwordRules.hasLower ? 'checked' : 'unchecked'}
            >
                {passwordRules.hasLower ? <CheckIcon /> : <MinusIcon />}
                <span>Uma letra minúscula</span>
            </CheckItem>
        </div>
        <span />
        <div className='reset-password-line-two'>
            <CheckItem
                status={passwordRules.hasUpper}
                className={passwordRules.hasUpper ? 'checked' : 'unchecked'}
            >
                {passwordRules.hasUpper ? <CheckIcon /> : <MinusIcon />}
                <span>Uma letra maiúscula</span>
            </CheckItem>
            <CheckItem
                status={passwordRules.hasNumber}
                className={passwordRules.hasNumber ? 'checked' : 'unchecked'}
            >
                {passwordRules.hasNumber ? <CheckIcon /> : <MinusIcon />}
                <span style={{ marginRight: '69px' }}>Um número</span>
            </CheckItem>
        </div>
    </div>
)

export default PasswordRequirements

const CheckItem = styled.div<{ status: boolean | null }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${fonts.jotaBodySmall};
    color: ${(props) =>
        props.status ? colors.green.jotaGreen : colors.gray.jotaGray};

    & > svg {
        fill: ${(props) =>
            props.status ? colors.green.jotaGreen : colors.gray.jotaGrayLight};
        margin-right: 8px;
    }
`
